:root {
  /* Surface tint color */
  --md-sys-elevation-surface-tint-color: var(--md-sys-color-primary);
  /* +5 */
  --md-sys-elevation-level5-value: 12px;
  --md-sys-elevation-level5-unit: 1px;
  --md-sys-elevation-level5: 12px;
  /* +4 */
  --md-sys-elevation-level4-value: 8px;
  --md-sys-elevation-level4-unit: 1px;
  --md-sys-elevation-level4: 8px;
  /* +3 */
  --md-sys-elevation-level3-value: 6px;
  --md-sys-elevation-level3-unit: 1px;
  --md-sys-elevation-level3: 6px;
  /* +2 */
  --md-sys-elevation-level2-value: 3px;
  --md-sys-elevation-level2-unit: 1px;
  --md-sys-elevation-level2: 3px;
  /* +1 */
  --md-sys-elevation-level1-value: 1px;
  --md-sys-elevation-level1-unit: 1px;
  --md-sys-elevation-level1: 1px;
  /* 0 */
  --md-sys-elevation-level0-value: 0px;
  --md-sys-elevation-level0-unit: 1px;
  --md-sys-elevation-level0: 0px;
}
.elevation-0 {
  box-shadow: var(--md-sys-elevation-level0);
}
.elevation-1 {
  box-shadow: var(--md-sys-elevation-level1);
}
.elevation-2 {
  box-shadow: var(--md-sys-elevation-level2);
}
.elevation-3 {
  box-shadow: var(--md-sys-elevation-level3);
}
.elevation-4 {
  box-shadow: var(--md-sys-elevation-level4);
}
.elevation-5 {
  box-shadow: var(--md-sys-elevation-level5);
}
