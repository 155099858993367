
:root {
  /* Dragged state layer opacity */
  --md-sys-state-dragged-state-layer-opacity: 0.1599999964237213;
  /* Pressed state layer opacity */
  --md-sys-state-pressed-state-layer-opacity: 0.11999999731779099;
  /* Focus state layer opacity */
  --md-sys-state-focus-state-layer-opacity: 0.11999999731779099;
  /* Hover state layer opacity */
  --md-sys-state-hover-state-layer-opacity: 0.07999999821186066;
}
.hover-state-layer {
  opacity: var(--md-sys-state-hover-state-layer-opacity);
}
.pressed-state-layer {
  opacity: var(--md-sys-state-pressed-state-layer-opacity);
}
.dragged-state-layer {
  opacity: var(--md-sys-state-dragged-state-layer-opacity);
}
.focus-state-layer {
  opacity: var(--md-sys-state-focus-state-layer-opacity);
}
