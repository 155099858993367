@font-face {
    font-family: "Pretendard";
    src: url("../font/PretendardVariable.woff2") format("woff2");
}
/*@font-face {*/
/*    font-family: 'GmarketSansMedium';*/
/*    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');*/
/*    font-weight: normal;*/
/*    font-style: normal;*/
/*}*/


:root {
    --md-ref-typeface-brand: 'Pretendard';
    --md-ref-typeface-plain: 'Pretendard';
    --md-sys-color-light-primary: rgb(21 26 77);
    --md-sys-color-surface-tint: rgb(86 89 146);
    --md-sys-color-light-on-primary: rgb(255 255 255);
    --md-sys-color-primary-container: rgb(224 224 255);
    --md-sys-color-on-primary-container: rgb(17 20 75);
    --md-sys-color-secondary: rgb(85 89 146);
    --md-sys-color-on-secondary: rgb(255 255 255);
    --md-sys-color-secondary-container: rgb(224 224 255);
    --md-sys-color-on-secondary-container: rgb(17 20 75);
    --md-sys-color-tertiary: rgb(57 96 143);
    --md-sys-color-on-tertiary: rgb(255 255 255);
    --md-sys-color-tertiary-container: rgb(211 228 255);
    --md-sys-color-on-tertiary-container: rgb(0 28 56);
    --md-sys-color-error: rgb(144 74 67);
    --md-sys-color-on-error: rgb(255 255 255);
    --md-sys-color-error-container: rgb(255 218 214);
    --md-sys-color-on-error-container: rgb(59 9 7);
    --md-sys-color-background: rgb(251 248 255);
    --md-sys-color-on-background: rgb(27 27 33);
    --md-sys-color-surface: rgb(251 248 255);
    --md-sys-color-on-surface: rgb(27 27 33);
    --md-sys-color-surface-variant: rgb(228 225 236);
    --md-sys-color-on-surface-variant: rgb(70 70 79);
    --md-sys-color-outline: rgb(119 118 128);
    --md-sys-color-outline-variant: rgb(199 197 208);
    --md-sys-color-shadow: rgb(0 0 0);
    --md-sys-color-scrim: rgb(0 0 0);
    --md-sys-color-inverse-surface: rgb(48 48 54);
    --md-sys-color-inverse-on-surface: rgb(242 239 247);
    --md-sys-color-inverse-primary: rgb(191 194 255);
    --md-sys-color-primary-fixed: rgb(224 224 255);
    --md-sys-color-on-primary-fixed: rgb(17 20 75);
    --md-sys-color-primary-fixed-dim: rgb(191 194 255);
    --md-sys-color-on-primary-fixed-variant: rgb(62 66 120);
    --md-sys-color-secondary-fixed: rgb(224 224 255);
    --md-sys-color-on-secondary-fixed: rgb(17 20 75);
    --md-sys-color-secondary-fixed-dim: rgb(190 194 255);
    --md-sys-color-on-secondary-fixed-variant: rgb(62 66 120);
    --md-sys-color-tertiary-fixed: rgb(211 228 255);
    --md-sys-color-on-tertiary-fixed: rgb(0 28 56);
    --md-sys-color-tertiary-fixed-dim: rgb(163 201 254);
    --md-sys-color-on-tertiary-fixed-variant: rgb(29 72 117);
    --md-sys-color-surface-dim: rgb(220 217 224);
    --md-sys-color-surface-bright: rgb(251 248 255);
    --md-sys-color-surface-container-lowest: rgb(255 255 255);
    --md-sys-color-surface-container-low: rgb(245 242 250);
    --md-sys-color-surface-container: rgb(240 236 244);
    --md-sys-color-surface-container-high: rgb(234 231 239);
    --md-sys-color-surface-container-highest: rgb(255 255 255);
    --md-primary-tab-container-color: rgb(255 255 255);
    --md-primary-tab-active-indicator-color: rgb(21 26 77);
    --md-primary-tab-active-label-text-color: rgb(21 26 77);
    --md-primary-tab-active-focus-label-text-color: rgb(21 26 77);
    --md-primary-tab-label-text-size: 24px;
    --md-secondary-tab-container-color: rgb(255 255 255);
    --md-secondary-tab-active-indicator-color: rgb(21 26 77);
    --md-secondary-tab-label-text-size: 20px;

}


