
:root {
  --md-sys-typescale-label-small-text-transform: unset;
  --md-sys-typescale-label-small-axis-value: unset;
  --md-sys-typescale-label-small-font-style: unset;
  --md-sys-typescale-label-small-text-decoration: unset;
  --md-sys-typescale-label-small-line-height-value: 16px;
  --md-sys-typescale-label-small-line-height-unit: 2px;
  --md-sys-typescale-label-small-line-height: 16px;
  --md-sys-typescale-label-small-tracking-value: 0.5px;
  --md-sys-typescale-label-small-tracking-unit: 2px;
  --md-sys-typescale-label-small-tracking: 0.5px;
  --md-sys-typescale-label-small-size-value: 11px;
  --md-sys-typescale-label-small-size-unit: 2px;
  --md-sys-typescale-label-small-size: 11px;
  --md-sys-typescale-label-small-weight: var(--md-ref-typeface-weight-medium);
  /* Label Small font name */
  --md-sys-typescale-label-small-font: var(--md-ref-typeface-plain);
  /* Label Medium */
  --md-sys-typescale-label-medium-axis-value: unset;
  --md-sys-typescale-label-medium-font-style: unset;
  --md-sys-typescale-label-medium-text-decoration: unset;
  /* Label Medium text transform */
  --md-sys-typescale-label-medium-text-transform: 1;
  /* Label Medium line height */
  --md-sys-typescale-label-medium-line-height-value: 16px;
  --md-sys-typescale-label-medium-line-height-unit: 2px;
  --md-sys-typescale-label-medium-line-height: 16px;
  /* Label Medium font tracking */
  --md-sys-typescale-label-medium-tracking-value: 0.5px;
  --md-sys-typescale-label-medium-tracking-unit: 2px;
  --md-sys-typescale-label-medium-tracking: 0.5px;
  /* Label Medium font size */
  --md-sys-typescale-label-medium-size-value: 12px;
  --md-sys-typescale-label-medium-size-unit: 2px;
  --md-sys-typescale-label-medium-size: 12px;
  /* Label Medium font weight */
  --md-sys-typescale-label-medium-weight: var(--md-ref-typeface-weight-medium);
  /* Label Medium font name */
  --md-sys-typescale-label-medium-font: var(--md-ref-typeface-plain);
  /* Label Large */
  --md-sys-typescale-label-large-text-transform: unset;
  --md-sys-typescale-label-large-axis-value: unset;
  --md-sys-typescale-label-large-font-style: unset;
  --md-sys-typescale-label-large-text-decoration: unset;
  /* Label Large line height */
  --md-sys-typescale-label-large-line-height-value: 20px;
  --md-sys-typescale-label-large-line-height-unit: 2px;
  --md-sys-typescale-label-large-line-height: 20px;
  /* Label Large font tracking */
  --md-sys-typescale-label-large-tracking-value: 0.10000000149011612px;
  --md-sys-typescale-label-large-tracking-unit: 2px;
  --md-sys-typescale-label-large-tracking: 0.10000000149011612px;
  /* Label Large font size */
  --md-sys-typescale-label-large-size-value: 14px;
  --md-sys-typescale-label-large-size-unit: 2px;
  --md-sys-typescale-label-large-size: 14px;
  /* Label Large font weight */
  --md-sys-typescale-label-large-weight: var(--md-ref-typeface-weight-medium);
  /* Label Large font name */
  --md-sys-typescale-label-large-font: var(--md-ref-typeface-plain);
  /* Body Small */
  --md-sys-typescale-body-small-text-transform: unset;
  --md-sys-typescale-body-small-axis-value: unset;
  --md-sys-typescale-body-small-font-style: unset;
  --md-sys-typescale-body-small-text-decoration: unset;
  /* Body Small line height */
  --md-sys-typescale-body-small-line-height-value: 16px;
  --md-sys-typescale-body-small-line-height-unit: 2px;
  --md-sys-typescale-body-small-line-height: 16px;
  /* Body Small font tracking */
  --md-sys-typescale-body-small-tracking-value: 0.4000000059604645px;
  --md-sys-typescale-body-small-tracking-unit: 2px;
  --md-sys-typescale-body-small-tracking: 0.4000000059604645px;
  /* Body Small font size */
  --md-sys-typescale-body-small-size-value: 12px;
  --md-sys-typescale-body-small-size-unit: 2px;
  --md-sys-typescale-body-small-size: 12px;
  /* Body Small font weight */
  --md-sys-typescale-body-small-weight: var(--md-ref-typeface-weight-regular);
  /* Body Small font name */
  --md-sys-typescale-body-small-font: var(--md-ref-typeface-plain);
  /* Body Medium */
  --md-sys-typescale-body-medium-text-transform: unset;
  --md-sys-typescale-body-medium-axis-value: unset;
  --md-sys-typescale-body-medium-font-style: unset;
  --md-sys-typescale-body-medium-text-decoration: unset;
  /* Body Medium line height */
  --md-sys-typescale-body-medium-line-height-value: 20px;
  --md-sys-typescale-body-medium-line-height-unit: 2px;
  --md-sys-typescale-body-medium-line-height: 20px;
  /* Body Medium font tracking */
  --md-sys-typescale-body-medium-tracking-value: 0.25px;
  --md-sys-typescale-body-medium-tracking-unit: 2px;
  --md-sys-typescale-body-medium-tracking: 0.25px;
  /* Body Medium font size */
  --md-sys-typescale-body-medium-size-value: 14px;
  --md-sys-typescale-body-medium-size-unit: 2px;
  --md-sys-typescale-body-medium-size: 14px;
  /* Body Medium font weight */
  --md-sys-typescale-body-medium-weight: var(--md-ref-typeface-weight-regular);
  /* Body Medium font name */
  --md-sys-typescale-body-medium-font: var(--md-ref-typeface-plain);
  /* Body Large */
  --md-sys-typescale-body-large-text-transform: unset;
  --md-sys-typescale-body-large-axis-value: unset;
  --md-sys-typescale-body-large-font-style: unset;
  --md-sys-typescale-body-large-text-decoration: unset;
  /* Body Large line height */
  --md-sys-typescale-body-large-line-height-value: 24px;
  --md-sys-typescale-body-large-line-height-unit: 2px;
  --md-sys-typescale-body-large-line-height: 24px;
  /* Body Large font tracking */
  --md-sys-typescale-body-large-tracking-value: 0.5px;
  --md-sys-typescale-body-large-tracking-unit: 2px;
  --md-sys-typescale-body-large-tracking: 0.5px;
  /* Body Large font size */
  --md-sys-typescale-body-large-size-value: 16px;
  --md-sys-typescale-body-large-size-unit: 2px;
  --md-sys-typescale-body-large-size: 16px;
  /* Body Large font weight */
  --md-sys-typescale-body-large-weight: var(--md-ref-typeface-weight-regular);
  /* Body Large font name */
  --md-sys-typescale-body-large-font: var(--md-ref-typeface-plain);
  /* Title Small */
  --md-sys-typescale-title-small-text-transform: unset;
  --md-sys-typescale-title-small-axis-value: unset;
  --md-sys-typescale-title-small-font-style: unset;
  --md-sys-typescale-title-small-text-decoration: unset;
  /* Title Small line height */
  --md-sys-typescale-title-small-line-height-value: 20px;
  --md-sys-typescale-title-small-line-height-unit: 2px;
  --md-sys-typescale-title-small-line-height: 20px;
  /* Title Small font tracking */
  --md-sys-typescale-title-small-tracking-value: 0.10000000149011612px;
  --md-sys-typescale-title-small-tracking-unit: 2px;
  --md-sys-typescale-title-small-tracking: 0.10000000149011612px;
  /* Title Small font size */
  --md-sys-typescale-title-small-size-value: 14px;
  --md-sys-typescale-title-small-size-unit: 2px;
  --md-sys-typescale-title-small-size: 14px;
  /* Title Small font weight */
  --md-sys-typescale-title-small-weight: var(--md-ref-typeface-weight-medium);
  /* Title Small font name */
  --md-sys-typescale-title-small-font: var(--md-ref-typeface-plain);
  /* Title Medium */
  --md-sys-typescale-title-medium-text-transform: unset;
  --md-sys-typescale-title-medium-axis-value: unset;
  --md-sys-typescale-title-medium-font-style: unset;
  --md-sys-typescale-title-medium-text-decoration: unset;
  /* Title Medium line height */
  --md-sys-typescale-title-medium-line-height-value: 24px;
  --md-sys-typescale-title-medium-line-height-unit: 2px;
  --md-sys-typescale-title-medium-line-height: 24px;
  /* Title Medium font tracking */
  --md-sys-typescale-title-medium-tracking-value: 0.15000000596046448px;
  --md-sys-typescale-title-medium-tracking-unit: 2px;
  --md-sys-typescale-title-medium-tracking: 0.15000000596046448px;
  /* Title Medium font size */
  --md-sys-typescale-title-medium-size-value: 16px;
  --md-sys-typescale-title-medium-size-unit: 2px;
  --md-sys-typescale-title-medium-size: 16px;
  /* Title Medium font weight */
  --md-sys-typescale-title-medium-weight: var(--md-ref-typeface-weight-medium);
  /* Title Medium font name */
  --md-sys-typescale-title-medium-font: var(--md-ref-typeface-plain);
  /* Title Large */
  --md-sys-typescale-title-large-text-transform: unset;
  --md-sys-typescale-title-large-axis-value: unset;
  --md-sys-typescale-title-large-font-style: unset;
  --md-sys-typescale-title-large-text-decoration: unset;
  /* Title Large line height */
  --md-sys-typescale-title-large-line-height-value: 28px;
  --md-sys-typescale-title-large-line-height-unit: 2px;
  --md-sys-typescale-title-large-line-height: 28px;
  /* Title Large font tracking */
  --md-sys-typescale-title-large-tracking-value: 0px;
  --md-sys-typescale-title-large-tracking-unit: 2px;
  --md-sys-typescale-title-large-tracking: 0px;
  /* Title Large font size */
  --md-sys-typescale-title-large-size-value: 22px;
  --md-sys-typescale-title-large-size-unit: 2px;
  --md-sys-typescale-title-large-size: 22px;
  /* Title Large font weight */
  --md-sys-typescale-title-large-weight: var(--md-ref-typeface-weight-regular);
  /* Title Large font name */
  --md-sys-typescale-title-large-font: var(--md-ref-typeface-brand);
  /* Headline Small */
  --md-sys-typescale-headline-small-text-transform: unset;
  --md-sys-typescale-headline-small-axis-value: unset;
  --md-sys-typescale-headline-small-font-style: unset;
  --md-sys-typescale-headline-small-text-decoration: unset;
  /* Headline Small line height */
  --md-sys-typescale-headline-small-line-height-value: 32px;
  --md-sys-typescale-headline-small-line-height-unit: 2px;
  --md-sys-typescale-headline-small-line-height: 32px;
  /* Headline Small font tracking */
  --md-sys-typescale-headline-small-tracking-value: 0px;
  --md-sys-typescale-headline-small-tracking-unit: 2px;
  --md-sys-typescale-headline-small-tracking: 0px;
  /* Headline Small font size */
  --md-sys-typescale-headline-small-size-value: 24px;
  --md-sys-typescale-headline-small-size-unit: 2px;
  --md-sys-typescale-headline-small-size: 24px;
  /* Headline Small font weight */
  --md-sys-typescale-headline-small-weight: var(
    --md-ref-typeface-weight-regular
  );
  /* Headline Small font name */
  --md-sys-typescale-headline-small-font: var(--md-ref-typeface-brand);
  /* Headline Medium */
  --md-sys-typescale-headline-medium-text-transform: unset;
  --md-sys-typescale-headline-medium-axis-value: unset;
  --md-sys-typescale-headline-medium-font-style: unset;
  --md-sys-typescale-headline-medium-text-decoration: unset;
  /* Headline Medium line height */
  --md-sys-typescale-headline-medium-line-height-value: 36px;
  --md-sys-typescale-headline-medium-line-height-unit: 2px;
  --md-sys-typescale-headline-medium-line-height: 36px;
  /* Headline Medium font tracking */
  --md-sys-typescale-headline-medium-tracking-value: 0px;
  --md-sys-typescale-headline-medium-tracking-unit: 2px;
  --md-sys-typescale-headline-medium-tracking: 0px;
  /* Headline Medium font size */
  --md-sys-typescale-headline-medium-size-value: 28px;
  --md-sys-typescale-headline-medium-size-unit: 2px;
  --md-sys-typescale-headline-medium-size: 28px;
  /* Headline Medium font weight */
  --md-sys-typescale-headline-medium-weight: var(
    --md-ref-typeface-weight-regular
  );
  /* Headline Medium font name */
  --md-sys-typescale-headline-medium-font: var(--md-ref-typeface-brand);
  /* Headline Large */
  --md-sys-typescale-headline-large-text-transform: unset;
  --md-sys-typescale-headline-large-axis-value: unset;
  --md-sys-typescale-headline-large-font-style: unset;
  --md-sys-typescale-headline-large-text-decoration: unset;
  /* Headline Large line height */
  --md-sys-typescale-headline-large-line-height-value: 40px;
  --md-sys-typescale-headline-large-line-height-unit: 2px;
  --md-sys-typescale-headline-large-line-height: 40px;
  /* Headline Large font tracking */
  --md-sys-typescale-headline-large-tracking-value: 0px;
  --md-sys-typescale-headline-large-tracking-unit: 2px;
  --md-sys-typescale-headline-large-tracking: 0px;
  /* Headline Large font size */
  --md-sys-typescale-headline-large-size-value: 32px;
  --md-sys-typescale-headline-large-size-unit: 2px;
  --md-sys-typescale-headline-large-size: 32px;
  /* Headline Large font name */
  --md-sys-typescale-headline-large-font: var(--md-ref-typeface-brand);
  /* Headline Large font weight */
  --md-sys-typescale-headline-large-weight: var(
    --md-ref-typeface-weight-regular
  );
  /* Display Small */
  --md-sys-typescale-display-small-text-transform: unset;
  --md-sys-typescale-display-small-axis-value: unset;
  --md-sys-typescale-display-small-font-style: unset;
  --md-sys-typescale-display-small-text-decoration: unset;
  /* Display Small line height */
  --md-sys-typescale-display-small-line-height-value: 44px;
  --md-sys-typescale-display-small-line-height-unit: 2px;
  --md-sys-typescale-display-small-line-height: 44px;
  /* Display Small font tracking */
  --md-sys-typescale-display-small-tracking-value: 0px;
  --md-sys-typescale-display-small-tracking-unit: 2px;
  --md-sys-typescale-display-small-tracking: 0px;
  /* Display Small font size */
  --md-sys-typescale-display-small-size-value: 36px;
  --md-sys-typescale-display-small-size-unit: 2px;
  --md-sys-typescale-display-small-size: 36px;
  /* Display Small font weight */
  --md-sys-typescale-display-small-weight: var(
    --md-ref-typeface-weight-regular
  );
  /* Display Small font name */
  --md-sys-typescale-display-small-font: var(--md-ref-typeface-brand);
  /* Display Medium */
  --md-sys-typescale-display-medium-text-transform: unset;
  --md-sys-typescale-display-medium-axis-value: unset;
  --md-sys-typescale-display-medium-font-style: unset;
  --md-sys-typescale-display-medium-text-decoration: unset;
  /* Display Medium line height */
  --md-sys-typescale-display-medium-line-height-value: 52px;
  --md-sys-typescale-display-medium-line-height-unit: 2px;
  --md-sys-typescale-display-medium-line-height: 52px;
  /* Display Medium font tracking */
  --md-sys-typescale-display-medium-tracking-value: 0px;
  --md-sys-typescale-display-medium-tracking-unit: 2px;
  --md-sys-typescale-display-medium-tracking: 0px;
  /* Display Medium font size */
  --md-sys-typescale-display-medium-size-value: 45px;
  --md-sys-typescale-display-medium-size-unit: 2px;
  --md-sys-typescale-display-medium-size: 45px;
  /* Display Medium font weight */
  --md-sys-typescale-display-medium-weight: var(
    --md-ref-typeface-weight-regular
  );
  /* Display Medium font name */
  --md-sys-typescale-display-medium-font: var(--md-ref-typeface-brand);
  /* Display Large */
  --md-sys-typescale-display-large-text-transform: unset;
  --md-sys-typescale-display-large-axis-value: unset;
  --md-sys-typescale-display-large-font-style: unset;
  --md-sys-typescale-display-large-text-decoration: unset;
  /* Display Large line height */
  --md-sys-typescale-display-large-line-height-value: 64px;
  --md-sys-typescale-display-large-line-height-unit: 2px;
  --md-sys-typescale-display-large-line-height: 64px;
  /* Display Large font tracking */
  --md-sys-typescale-display-large-tracking-value: -0.25px;
  --md-sys-typescale-display-large-tracking-unit: 2px;
  --md-sys-typescale-display-large-tracking: -0.25px;
  /* Display Large font size */
  --md-sys-typescale-display-large-size-value: 57px;
  --md-sys-typescale-display-large-size-unit: 2px;
  --md-sys-typescale-display-large-size: 57px;
  /* Display Large font weight */
  --md-sys-typescale-display-large-weight: var(
    --md-ref-typeface-weight-regular
  );
  /* Display Large font name */
  --md-sys-typescale-display-large-font: var(--md-ref-typeface-brand);
  /* Plain typeface */
  --md-ref-typeface-plain: 'Pretendard';
  /* Brand typeface */
  --md-ref-typeface-brand: 'Pretendard';
  /* Bold weight */
  --md-ref-typeface-weight-bold: 700;
  /* Medium weight */
  --md-ref-typeface-weight-medium: 500;
  /* Regular weight */
  --md-ref-typeface-weight-regular: 400;
}

/* Label Small */
.label-small {
  font-family: var(--md-sys-typescale-label-small-font);
  font-weight: var(--md-sys-typescale-label-small-weight);
  font-size: var(--md-sys-typescale-label-small-size);
  font-style: var(--md-sys-typescale-label-small-font-style);
  letter-spacing: var(--md-sys-typescale-label-small-tracking);
  line-height: var(--md-sys-typescale-label-small-line-height);
  text-transform: var(--md-sys-typescale-label-small-text-transform);
  text-decoration: var(--md-sys-typescale-label-small-text-decoration);
}
/* Label Medium */
.label-medium {
  font-family: var(--md-sys-typescale-label-medium-font);
  font-weight: var(--md-sys-typescale-label-medium-weight);
  font-size: var(--md-sys-typescale-label-medium-size);
  font-style: var(--md-sys-typescale-label-medium-font-style);
  letter-spacing: var(--md-sys-typescale-label-medium-tracking);
  line-height: var(--md-sys-typescale-label-medium-line-height);
  text-transform: var(--md-sys-typescale-label-medium-text-transform);
  text-decoration: var(--md-sys-typescale-label-medium-text-decoration);
}
/* Label Large */
.label-large {
  font-family: var(--md-sys-typescale-label-large-font);
  font-weight: var(--md-sys-typescale-label-large-weight);
  font-size: var(--md-sys-typescale-label-large-size);
  font-style: var(--md-sys-typescale-label-large-font-style);
  letter-spacing: var(--md-sys-typescale-label-large-tracking);
  line-height: var(--md-sys-typescale-label-large-line-height);
  text-transform: var(--md-sys-typescale-label-large-text-transform);
  text-decoration: var(--md-sys-typescale-label-large-text-decoration);
}
/* Body Small */
.body-small {
  font-family: var(--md-sys-typescale-body-small-font);
  font-weight: var(--md-sys-typescale-body-small-weight);
  font-size: var(--md-sys-typescale-body-small-size);
  font-style: var(--md-sys-typescale-body-small-font-style);
  letter-spacing: var(--md-sys-typescale-body-small-tracking);
  line-height: var(--md-sys-typescale-body-small-line-height);
  text-transform: var(--md-sys-typescale-body-small-text-transform);
  text-decoration: var(--md-sys-typescale-body-small-text-decoration);
}
/* Body Medium */
.body-medium {
  font-family: var(--md-sys-typescale-body-medium-font);
  font-weight: var(--md-sys-typescale-body-medium-weight);
  font-size: var(--md-sys-typescale-body-medium-size);
  font-style: var(--md-sys-typescale-body-medium-font-style);
  letter-spacing: var(--md-sys-typescale-body-medium-tracking);
  line-height: var(--md-sys-typescale-body-medium-line-height);
  text-transform: var(--md-sys-typescale-body-medium-text-transform);
  text-decoration: var(--md-sys-typescale-body-medium-text-decoration);
}
/* Body Large */
.body-large {
  font-family: var(--md-sys-typescale-body-large-font);
  font-weight: var(--md-sys-typescale-body-large-weight);
  font-size: var(--md-sys-typescale-body-large-size);
  font-style: var(--md-sys-typescale-body-large-font-style);
  letter-spacing: var(--md-sys-typescale-body-large-tracking);
  line-height: var(--md-sys-typescale-body-large-line-height);
  text-transform: var(--md-sys-typescale-body-large-text-transform);
  text-decoration: var(--md-sys-typescale-body-large-text-decoration);
}
/* Title Small */
.title-small {
  font-family: var(--md-sys-typescale-title-small-font);
  font-weight: var(--md-sys-typescale-title-small-weight);
  font-size: var(--md-sys-typescale-title-small-size);
  font-style: var(--md-sys-typescale-title-small-font-style);
  letter-spacing: var(--md-sys-typescale-title-small-tracking);
  line-height: var(--md-sys-typescale-title-small-line-height);
  text-transform: var(--md-sys-typescale-title-small-text-transform);
  text-decoration: var(--md-sys-typescale-title-small-text-decoration);
}
/* Title Medium */
.title-medium {
  font-family: var(--md-sys-typescale-title-medium-font);
  font-weight: var(--md-sys-typescale-title-medium-weight);
  font-size: var(--md-sys-typescale-title-medium-size);
  font-style: var(--md-sys-typescale-title-medium-font-style);
  letter-spacing: var(--md-sys-typescale-title-medium-tracking);
  line-height: var(--md-sys-typescale-title-medium-line-height);
  text-transform: var(--md-sys-typescale-title-medium-text-transform);
  text-decoration: var(--md-sys-typescale-title-medium-text-decoration);
}
/* Title Large */
.title-large {
  font-family: var(--md-sys-typescale-title-large-font);
  font-weight: var(--md-sys-typescale-title-large-weight);
  font-size: var(--md-sys-typescale-title-large-size);
  font-style: var(--md-sys-typescale-title-large-font-style);
  letter-spacing: var(--md-sys-typescale-title-large-tracking);
  line-height: var(--md-sys-typescale-title-large-line-height);
  text-transform: var(--md-sys-typescale-title-large-text-transform);
  text-decoration: var(--md-sys-typescale-title-large-text-decoration);
}
/* Headline Small */
.headline-small {
  font-family: var(--md-sys-typescale-headline-small-font);
  font-weight: var(--md-sys-typescale-headline-small-weight);
  font-size: var(--md-sys-typescale-headline-small-size);
  font-style: var(--md-sys-typescale-headline-small-font-style);
  letter-spacing: var(--md-sys-typescale-headline-small-tracking);
  line-height: var(--md-sys-typescale-headline-small-line-height);
  text-transform: var(--md-sys-typescale-headline-small-text-transform);
  text-decoration: var(--md-sys-typescale-headline-small-text-decoration);
}
/* Headline Medium */
.headline-medium {
  font-family: var(--md-sys-typescale-headline-medium-font);
  font-weight: var(--md-sys-typescale-headline-medium-weight);
  font-size: var(--md-sys-typescale-headline-medium-size);
  font-style: var(--md-sys-typescale-headline-medium-font-style);
  letter-spacing: var(--md-sys-typescale-headline-medium-tracking);
  line-height: var(--md-sys-typescale-headline-medium-line-height);
  text-transform: var(--md-sys-typescale-headline-medium-text-transform);
  text-decoration: var(--md-sys-typescale-headline-medium-text-decoration);
}
/* Headline Large */
.headline-large {
  font-family: var(--md-sys-typescale-headline-large-font);
  font-weight: var(--md-sys-typescale-headline-large-weight);
  font-size: var(--md-sys-typescale-headline-large-size);
  font-style: var(--md-sys-typescale-headline-large-font-style);
  letter-spacing: var(--md-sys-typescale-headline-large-tracking);
  line-height: var(--md-sys-typescale-headline-large-line-height);
  text-transform: var(--md-sys-typescale-headline-large-text-transform);
  text-decoration: var(--md-sys-typescale-headline-large-text-decoration);
}
/* Display Small */
.display-small {
  font-family: var(--md-sys-typescale-display-small-font);
  font-weight: var(--md-sys-typescale-display-small-weight);
  font-size: var(--md-sys-typescale-display-small-size);
  font-style: var(--md-sys-typescale-display-small-font-style);
  letter-spacing: var(--md-sys-typescale-display-small-tracking);
  line-height: var(--md-sys-typescale-display-small-line-height);
  text-transform: var(--md-sys-typescale-display-small-text-transform);
  text-decoration: var(--md-sys-typescale-display-small-text-decoration);
}
/* Display Medium */
.display-medium {
  font-family: var(--md-sys-typescale-display-medium-font);
  font-weight: var(--md-sys-typescale-display-medium-weight);
  font-size: var(--md-sys-typescale-display-medium-size);
  font-style: var(--md-sys-typescale-display-medium-font-style);
  letter-spacing: var(--md-sys-typescale-display-medium-tracking);
  line-height: var(--md-sys-typescale-display-medium-line-height);
  text-transform: var(--md-sys-typescale-display-medium-text-transform);
  text-decoration: var(--md-sys-typescale-display-medium-text-decoration);
}
/* Display Large */
.display-large {
  font-family: var(--md-sys-typescale-display-large-font);
  font-weight: var(--md-sys-typescale-display-large-weight);
  font-size: var(--md-sys-typescale-display-large-size);
  font-style: var(--md-sys-typescale-display-large-font-style);
  letter-spacing: var(--md-sys-typescale-display-large-tracking);
  line-height: var(--md-sys-typescale-display-large-line-height);
  text-transform: var(--md-sys-typescale-display-large-text-transform);
  text-decoration: var(--md-sys-typescale-display-large-text-decoration);
}
